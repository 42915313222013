<template>
	<v-container>
		<div class="report-formation-container">
			<CongrateFinishFormation
				:parcours="thisParcours"
				:class="{ 'mt-7': $vuetify.breakpoint.mdAndUp, 'mt-2': $vuetify.breakpoint.smAndDown }"
			/>
			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
			<IntroduceOtherParcoursCarousel
				v-if="fetching === false && parcoursNotOwned.length !== 0"
				:formationConfig="formationProgress.config"
				:parcoursNotOwned="parcoursNotOwned"
			/>
			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
			<TotalEarnedPoints :parcours="thisParcours" />
			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
			<RulesStatistics :parcours="thisParcours" />
			<v-btn
				v-if="fetching === false && parcoursNotOwned.length === 0"
				depressed
				:x-large="$vuetify.breakpoint.mdAndUp"
				:large="$vuetify.breakpoint.smAndDown"
				dark
				color="#7854f7"
				class="next-btn hvr-grow"
				:to="{ name: 'Parcours Detail', params: { idParcours: thisParcours._id } }"
			>
				<span class="white--text">Retourner à mon espace perso</span>
			</v-btn>
			<v-btn
				v-if="fetching === false && parcoursNotOwned.length > 0"
				depressed
				:x-large="$vuetify.breakpoint.mdAndUp"
				:large="$vuetify.breakpoint.smAndDown"
				dark
				color="#7854f7"
				class="next-btn hvr-grow"
				target="_blank"
				href="https://meetings-eu1.hubspot.com/rendez-vous-csm?uuid=2e0446cd-e95e-42a1-b311-a21d2b36ba22?utm_campaign=end-parcours&utm_source=main-cta-report"
			>
				<span class="white--text">Débloquer un nouveau Parcours </span>
			</v-btn>
			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
		</div>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import CongrateFinishFormation from '@/components/reports/formation/CongrateFinishFormation';
import IntroduceOtherParcoursCarousel from '@/components/reports/formation/IntroduceOtherParcoursCarousel';
import TotalEarnedPoints from '@/components/reports/parcours/TotalEarnedPoints';
import RulesStatistics from '@/components/reports/parcours/RulesStatistics';
import { changeBgColorById } from '@/utils/css-util.js';
import { setZindexByClassNames, setDisplay } from '@/utils/helper.js';

export default {
	name: 'ReportFormation',
	components: { CongrateFinishFormation, IntroduceOtherParcoursCarousel, TotalEarnedPoints, RulesStatistics },
	data() {
		return {
			thisParcours: null,
			thisTopic: null,
			fetching: true,
			parcoursNotOwned: [],
			formationProgress: null,
		};
	},
	computed: { ...mapGetters('resources', { topics: 'topics' }) },
	async created() {
		this.formationProgress = await this.$store.dispatch('profile/formationProgress', {
			idFormation: this.$route.params.idFormation,
		});
		const idParcours = this.$route.params.idParcours;
		const indexOfParcours = this.formationProgress.config.parcours_list.findIndex(
			(parcoursItem) => parcoursItem.parcours_id._id === idParcours
		);
		if (indexOfParcours !== -1)
			this.thisParcours = this.formationProgress.config.parcours_list[indexOfParcours].parcours_id;

		this.thisTopic = await this.findTopicOfFormationConfig();
		this.parcoursNotOwned = await this.getParcoursNotOwned();

		this.fetching = false;
	},
	mounted() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		changeBgColorById('reportIndex', '#120136');
		setZindexByClassNames(['navbar'], 1);
		if (window.innerWidth < 500) setDisplay('cc-157aw', 'none', 'important'); // Hide chat support
	},
	beforeDestroyed() {
		if (window.innerWidth < 500) setDisplay('cc-157aw', 'block'); // Unhide chat support
	},
	methods: {
		async findTopicOfFormationConfig() {
			const thisTopicId = this.formationProgress.config.topic_id._id;
			let topic = this.topics.find((topic) => topic._id === thisTopicId);
			if (topic == null) {
				await this.$store.dispatch('resources/getTopicAndParcours', { topicId: thisTopicId });
				topic = this.topics.find((topic) => topic._id === thisTopicId);
			}
			return topic;
		},
		async getParcoursNotOwned() {
			const parcoursUserNotHave = [];
			const allTopics = await this.$store.dispatch('resources/getAllTopicsAndParcours');
			const parcoursOfUser = await this.$store.dispatch('parcours/getAllParcoursByUserId', {
				userId: this.formationProgress.formation.user_id,
			});

			allTopics.forEach((topic) => {
				topic.parcours.forEach((p) => {
					p.topicName = topic.name;
					const parcoursIndex = parcoursOfUser.findIndex((userParcours) => userParcours._id === p._id);
					if (parcoursIndex === -1) parcoursUserNotHave.push(p);
				});
			});

			return parcoursUserNotHave;
		},
	},
};
</script>

<style lang="scss" scoped>
.report-formation-container {
	margin: 0 auto;
	padding-bottom: 40px;
	max-width: 800px;

	.next-btn {
		position: fixed;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
	}
}
</style>
